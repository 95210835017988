@font-face {
    font-family: 'Futura PT';
    src: url('/assets/fonts/FuturaPT/FuturaPT-Heavy.eot');
    src: url('/assets/fonts/FuturaPT/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Heavy.woff2') format('woff2'),
        url('/assets/fonts/FuturaPT/FuturaPT-Heavy.woff') format('woff'),
        url('/assets/fonts/FuturaPT/FuturaPT-Heavy.ttf') format('truetype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Heavy.svg#FuturaPT-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('/assets/fonts/FuturaPT/FuturaPT-Demi.eot');
    src: url('/assets/fonts/FuturaPT/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Demi.woff2') format('woff2'),
        url('/assets/fonts/FuturaPT/FuturaPT-Demi.woff') format('woff'),
        url('/assets/fonts/FuturaPT/FuturaPT-Demi.ttf') format('truetype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Demi.svg#FuturaPT-Demi') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Bold';
    src: url('/assets/fonts/FuturaPT/FuturaPT-Bold.eot');
    src: url('/assets/fonts/FuturaPT/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Bold.woff2') format('woff2'),
        url('/assets/fonts/FuturaPT/FuturaPT-Bold.woff') format('woff'),
        url('/assets/fonts/FuturaPT/FuturaPT-Bold.ttf') format('truetype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Bold.svg#FuturaPT-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('/assets/fonts/FuturaPT/FuturaPT-Book.eot');
    src: url('/assets/fonts/FuturaPT/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Book.woff2') format('woff2'),
        url('/assets/fonts/FuturaPT/FuturaPT-Book.woff') format('woff'),
        url('/assets/fonts/FuturaPT/FuturaPT-Book.ttf') format('truetype'),
        url('/assets/fonts/FuturaPT/FuturaPT-Book.svg#FuturaPT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

