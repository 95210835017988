/* You can add global styles to this file, and also import other style files */

:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/logo-dark.svg');
  --lpx-logo-icon: url('/assets/images/logo/logo.png');
  --rw-bg-color: #1B1B1B;
  --rw-progress-unfilled-color: var(--bs-body-color);
  --lpx-scroll-bar-bg: var(--bs-body-color);
}

:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/logo-light.svg');
  --lpx-logo-icon: url('/assets/images/logo/logo.png');
  --rw-bg-color: #fff;
  --lpx-scroll-bar-bg: #F0F4F7;
}

:root .lpx-theme-dim {
  --lpx-logo: url('/assets/images/logo/logo-dark.svg');
  --lpx-logo-icon: url('/assets/images/logo/logo.png');
  --rw-bg-color: #fff;
  --lpx-scroll-bar-bg: #F0F4F7;
}

:root {
  --lpx-theme-light-bg: url('/assets/images/login/login-bg-img.svg');
  --lpx-theme-dim-bg: url('/assets/images/login/login-bg-img.svg');
  --lpx-theme-dark-bg: url('/assets/images/login/login-bg-img.svg');
  --lpx-brand: #009247;
  --lpx-brand-darker: #016e36;
  --lpx-brand-muted: #478565;
  --lpx-brand-muted-text: #382E2E;
}

body {
  font-family: 'Futura PT', var(--bs-body-font-family);
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Futura PT Book', var(--bs-body-font-family);
}

.btn-secondary {
  --bs-btn-color: var(--lpx-brand);
  --bs-btn-border-color: var(--lpx-brand);
  --bs-btn-hover-color: var(--rw-bg-color);
  --bs-btn-hover-bg: var(--lpx-brand);
  --bs-btn-hover-border-color: var(--lpx-brand);
  --bs-btn-focus-shadow-rgb: 57, 117, 142;
  --bs-btn-active-color: var(--rw-bg-color);
  --bs-btn-active-bg: var(--lpx-brand-darker);
  --bs-btn-active-border-color: var(--lpx-brand-darker);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-bg: transparent;
}

.btn-primary {
  --bs-btn-color: var(--rw-bg-color);
  --bs-btn-bg: var(--lpx-brand);
  --bs-btn-border-color: var(--lpx-brand);
  --bs-btn-hover-color: var(--rw-bg-color);
  --bs-btn-hover-bg: var(--lpx-brand-darker);
  --bs-btn-hover-border-color: var(--lpx-brand-darker);
  --bs-btn-focus-shadow-rgb: 57, 117, 142;
  --bs-btn-active-color: var(--rw-bg-color);
  --bs-btn-active-bg: var(--lpx-brand);
  --bs-btn-active-border-color: var(--lpx-brand-darker);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--lpx-brand-muted-text);
  --bs-btn-disabled-bg: var(--lpx-brand-muted);
  --bs-btn-disabled-border-color: var(--lpx-brand-muted);
}

.btn-link {
  --bs-btn-color: var(--lpx-brand);
  --bs-btn-hover-color: var(--lpx-brand-darker);
  --bs-btn-active-color: var(--lpx-brand-darker);
}

::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: var(--lpx-scroll-bar-bg);
  border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--lpx-brand-darker);
}

.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: var(--lpx-brand);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--lpx-brand);
  border-color: var(--lpx-brand);
}

.lpx-content-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.lpx-content {
  min-height: calc(100vh - 33px);
}

@media only screen and (max-width: 767px) {
  .custom-container {
    margin-bottom: 72px;
  }
}

.lpx-mobile-nav-tab .mobile-item-text {
  white-space: wrap;
}

.lpx-mobile-nav-tab {
  max-width: 50%;
}